import { z } from 'zod'

import { Image, Images } from './image.js'
const IntBooleanSchema = z
  .number()
  .refine((value) => value === 0 || value === 1)
  .transform((value) => value === 1)
export const BlockedEnum = z.enum(['no', 'invoice', 'all'])

/** @typedef {z.infer<typeof StoreConfig>} StoreConfig */
export const StoreConfig = z.object({
    id: z.coerce.number(),
    bpId: z.number().nullish(),
    invoiceAccount: z.string().nullish(),
    inventLocation: z.string().nullish(),
    inventProductAllowanceGroupId: z.string().nullish(),
    sendDeliveryNotification: z.boolean().nullish(),
    shopActive: z.boolean().nullish(),
    GRAActive: z.boolean().nullish(),
    storeFinderActive: z.boolean().nullish(),
    registrationActive: z.boolean().nullish(),
    accountExecutiveCode: z.string(),
    salesOrderHandlingGroup: z.string().nullish(),
    customersCategory: z.string().nullish(),
    blocked: BlockedEnum.nullish(),
    retailGroupCodes: z.string().nullish(),
    dealerStoreCategory: z.string().nullish(),
    emailMarketing: z.string().nullish(),
    emailShipment: z.string().nullish(),
    productAllowanceGroup: z.string().nullish(),
    radius: z.number().nullish(),
    epBlockShipping: z.boolean().nullish(),
    creditRep: z.string().nullish(),
    destinationCode: z.string().nullish(),
    epReturnOrderBikeEnabled: z.boolean().nullish(),
    epReturnOrderGearEnabled: z.boolean().nullish(),
    preLabelPrint: z.boolean().nullish(),
    freightAllowanceCode: z.string().nullish(),
    internalSalesRep: z.string().nullish(),
    qaRep: z.string().nullish(),
    quickBooksAPNumber: z.string().nullish(),
    quickBooksClassNumber: z.string().nullish(),
    quickBooksFreightNumber: z.string().nullish(),
    QuickBooksAccountNumber: z.string().nullish(),
    retailDirectToConsumer: z.boolean().nullish(),
    shopLocalStockOnly: z.boolean().nullish(),
    webLinkEnable: z.boolean().nullish(),
    modifiedAt: z.date().nullish(),
})
