import {rest} from 'msw'
import {parse} from 'qs'

import {CMS_HOST} from '@reditor/core/config'

import {
  RequestParams,
  getRandomItemOrNull,
  getRandomItem,
  getRandomItems,
  randomDate,
  randomTime,
  filterItems,
} from './common'

let id = 1

let items = Array(103)
  .fill(undefined)
  .map((_, index) => {
    return {
      id: id++,
      bpId: null,
      url: `BusinessPartnerWritable url ${index}`,
      Facebook: `BusinessPartnerWritable Facebook ${index}`,
      instagram: `BusinessPartnerWritable instagram ${index}`,
      createdAt: randomTime('HH:mm:ss.SSS'),
      modifiedAt: randomTime('HH:mm:ss.SSS'),
    }
  })
  .reverse()

export const getItems = () => items

export const handlers = [
  // Handles a GET /api/business-partner-writables/{id} request
  rest.get(
    `${CMS_HOST}/api/business-partner-writables/:id`,
    (req, res, ctx) => {
      const id = req.params.id
      const item = items.find((item) => item.id === Number(id))

      return res(ctx.delay(), ctx.status(200), ctx.json(item))
    },
  ),

  // Handles a GET /api/business-partner-writables request
  rest.get(`${CMS_HOST}/api/business-partner-writables`, (req, res, ctx) => {
    const requestParams = RequestParams.parse(
      parse(req.url.search, {ignoreQueryPrefix: true}),
    )
    const page = requestParams.pagination.page
    const pageSize = requestParams.pagination.pageSize
    const filters = requestParams.filters

    // filter items
    const filteredItems = filterItems(items, filters)

    return res(
      ctx.delay(),
      ctx.status(200),
      ctx.json({
        list: filteredItems.slice((page - 1) * pageSize, page * pageSize),
        page,
        pageSize,
        pageCount: Math.ceil(filteredItems.length / pageSize),
        total: filteredItems.length,
      }),
    )
  }),

  // Handles a POST /api/business-partner-writables request
  rest.post(
    `${CMS_HOST}/api/business-partner-writables`,
    async (req, res, ctx) => {
      const item = await req.json()
      item.id = id++

      // if the key is a relation, find the item(s)
      Object.keys(item).forEach((key) => {
        let value = item[key]
      })

      items.unshift(item)

      return res(ctx.delay(), ctx.status(200), ctx.json(item))
    },
  ),

  // Handles a PUT /api/business-partner-writables/{id} request
  rest.put(
    `${CMS_HOST}/api/business-partner-writables/:id`,
    async (req, res, ctx) => {
      const id = req.params.id
      const item = items.find((item) => item.id === Number(id))

      const editedItem = await req.json()

      // if the key is a relation, find the item(s)
      Object.keys(editedItem).forEach((key) => {
        let value = editedItem[key]
      })

      // update item
      item && Object.assign(item, editedItem)

      return res(ctx.delay(), ctx.status(200), ctx.json(item))
    },
  ),

  //Handles a DELETE /api/business-partner-writables/{id} request
  rest.delete(
    `${CMS_HOST}/api/business-partner-writables/:id`,
    (req, res, ctx) => {
      const id = req.params.id
      const index = items.findIndex((item) => item.id === Number(id))

      // return 404 if the item isn't found
      if (index === -1) {
        return res(ctx.delay(), ctx.status(404), ctx.json({id: Number(id)}))
      }

      // delete item at index
      const [deletedItem] = items.splice(index, 1)

      return res(ctx.delay(), ctx.status(200), ctx.json(deletedItem))
    },
  ),
]
