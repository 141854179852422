import React from "react"
import { ToastBar, Toaster, toast as hotToast } from "react-hot-toast"
import { Icon } from "../ui-kit"

const ToastTypeEnum = Object.freeze({
  Success: "success",
  Warning: "warning",
  Error: "error",
})
/** @typedef {typeof ToastTypeEnum[keyof typeof ToastTypeEnum]} ToastType*/

/**
 *
 * @param {string} message
 * @param {ToastType} [type]
 */
function toast(message, type = ToastTypeEnum.Success) {
  switch (type) {
    case ToastTypeEnum.Error:
    case ToastTypeEnum.Warning:
      hotToast.error(message)
      break
    case ToastTypeEnum.Success:
    default:
      hotToast.success(message)
      break
  }
}

export function ToastContainer() {
  return (
    <Toaster
      containerStyle={{
        top: 80,
      }}
      toastOptions={{
        className: "text-sm font-semibold",
        style: {
          border: "0.5px solid #005EB8",
          boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
          borderRadius: "4px",
          maxWidth: "100vh",
          padding: "12px 16px",
          overflow: "hidden",
        },
        success: {
          icon: <Icon icon="ic_successful" />,
          style: {
            background: "#DDE4ED",
            border: "0.5px solid #005EB8",
            color: "#005EB8",
          },
          duration: 4000,
        },
        error: {
          icon: <Icon icon="ic_wrong" />,
          style: {
            background: "#FFE1D7",
            border: "0.5px solid #FF6A39",
            color: "#FF6A39",
          },
          duration: 4000,
        },
      }}>
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <div className="flex items-center">
              {icon}
              <div>{message}</div>
            </div>
          )}
        </ToastBar>
      )}
    </Toaster>
  )
}

export { ToastTypeEnum, toast }
