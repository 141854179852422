import { cva } from "class-variance-authority"

export const flexTableWrapperVariant = cva(
  ["flex flex-col overflow-y-hidden"],
  {
    variants: {
      width: {
        fit: ["w-fit"],
        full: ["w-full"],
      },
    },
    defaultVariants: {
      width: "fit",
    },
  },
)
