import { z } from 'zod'

import { Image, Images } from './image.js'

export const AddressTypeEnum = z.enum([
    'None',
    'Invoice',
    'Delivery',
    'AltDlv',
    'SWIFT',
    'Payment',
    'Service',
    'Home',
    'Other',
    'Business',
    'RemitTo',
    'ShipCarrierThirdPartyShipping',
    'DEL_BKDRemitTo',
    'Internet',
    ''
])

/** @typedef {z.infer<typeof Address>} Address */
export const Address = z.object({
    id: z.coerce.number().nullish(),
    sourceType: z.string().nullish(),
    sourceRecId: z.number().nullish(),
    addressType: AddressTypeEnum.nullish(),
    city: z.string(),
    street: z.string(),
    zipcode: z.string(),
    stateProvince: z.string().nullish(),
    country: z.string(),
    district: z.string().nullish(),
    bpId: z.number().nullish(),
    modifiedAt: z.date().nullish(),
})
