/**
 * @file This is the layout component that wraps the page content with a header.
 * if pageContext.withHeaderLayout is false, it will render children directly.
 * pageContext is passed from gatsby-node onCreatePage hook.
 */
import * as React from "react"

import { twMerge } from "../ui-kit/twmerge"

import PageHeader from "../components/PageHeader"

/**
 * @typedef {object} PageContext
 * @property {boolean=} withHeaderLayout
 * @property {boolean=} hideHeaderLinks
 */

/**
 * @description This is a layout component that wraps the page content with a header.
 * children is wrapped in a main tag where the height should minus the header height.
 *
 * @type {React.FC<import('gatsby').PageProps<{}, PageContext>>}
 */
const _PageLayoutWithHeader = ({
  children,
  pageContext = { withHeaderLayout: false, hideHeaderLinks: false },
}) => {
  if (!pageContext.withHeaderLayout) {
    return children
  }
  return (
    <>
      <PageHeader hideLinks={pageContext.hideHeaderLinks} />
      <main
        className={twMerge(
          `h-[calc(100vh-theme(height.header))] relative w-full overflow-x-hidden bg-light`,
          "px-3",
        )}>
        {children}
      </main>
    </>
  )
}

/**
 * @type {React.FC<PageContext & {
 *   children: React.ReactNode
 * }>}
 */
export const PageLayoutWithHeader = ({
  children,
  withHeaderLayout = false,
  hideHeaderLinks = false,
}) => {
  return (
    <_PageLayoutWithHeader pageContext={{ withHeaderLayout, hideHeaderLinks }}>
      {/* @ts-ignore */}
      {children}
    </_PageLayoutWithHeader>
  )
}

export default _PageLayoutWithHeader
