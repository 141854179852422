import { cva } from "class-variance-authority"

export const buttonVariants = cva(["py-1.5", "px-3", "rounded"], {
  variants: {
    variant: {
      primary: [
        "bg-primary",
        "text-white",
        "hover:bg-opacity-90",
        "disabled:bg-grey-light",
        "disabled:text-grey-70",
        "disabled:hover:bg-opacity-100",
      ],
      secondary: [
        "bg-white",
        "border",
        "border-solid",
        "border-grey-light",
        "text-grey",
        "hover:border-primary",
        "disabled:text-grey-70",
        "disabled:hover:border-grey-light",
      ],
      text: [
        "bg-transparent",
        "text-primary",
        "font-semibold",
        "hover:text-opacity-90",
        "disabled:hover:text-opacity-100",
      ],
    },
  },
  defaultVariants: {
    variant: "primary",
  },
})
