import {z} from 'zod'

/**
 * @typedef {object} RequestConfig
 * @property {string=} path
 * @property {RequestAction=} action
 * @property {Record<string, any>=} params
 * @property {any=} data
 * @property {any=} headers
 * @property {GenericAbortSignal=} signal
 * @property {string=} baseURL
 */

/**
 * @typedef {Pick<RequestConfig, "signal">} RequestSignalConfig
 */

/**
 * @param {RequestConfig} config
 * @returns {Promise<any>}
 */
const request = async (config) => {
  Promise.reject(new Error('DataRequestGateway not implemented'))
}

let defaultDateRequestGateway = {
  request: request,
}

/**
 * @typedef {typeof defaultDateRequestGateway} DataRequestGatewayInterface
 */

/**
 * @returns {DataRequestGatewayInterface}
 */
export const getDefaultGateway = () => defaultDateRequestGateway

/**
 * @param {DataRequestGatewayInterface} gateway
 */
export const setDefaultGateway = (gateway) => {
  defaultDateRequestGateway = gateway
}

/** @typedef {z.infer<typeof RequestAction>} RequestAction */
export const RequestAction = z.enum([
  'CREATE',
  'READ',
  'READ_BY_PARAM',
  'READ_MANY',
  'UPDATE',
  'DELETE',
  'PATCH',
])

/**
 * @typedef {object} GenericAbortSignal
 * @property {boolean} aborted
 * @property {((...args: any) => any) | null} onabort
 * @property {(...args: any) => any} addEventListener
 * @property {(...args: any) => any} removeEventListener
 */
