import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import * as React from "react"

import { AlertDialogContainer } from "../lib/alert-dialog"
import { ToastContainer } from "../lib/toast"
import { AlertDialog } from "../ui-kit"

/**
 * @param {{children: React.ReactElement}} param0
 */
export default function PageProvider({ children }) {
  return (
    <>
      {children}
      <ToastContainer />
      <AlertDialogContainer component={AlertDialog} />
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  )
}
