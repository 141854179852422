import * as React from "react"

import { Skeleton } from "../skeleton"
import * as FlexTable from "./FlexTable"

/**
 * @param {import('./type').ReditorUIKitTableBodySkeletonProps} props
 */
export const FlexTableBodySkeleton = ({ columns }) => {
  return (
    <FlexTable.TableBody>
      {Array.from({ length: 30 }).map((_, i) => (
        <FlexTable.TableRow key={i.toString()}>
          {columns.map((column, j) => {
            const size = column.size
            return (
              <FlexTable.TableCell style={{ width: size }} key={j.toString()}>
                <Skeleton className="h-4 w-full" />
              </FlexTable.TableCell>
            )
          })}
        </FlexTable.TableRow>
      ))}
    </FlexTable.TableBody>
  )
}
