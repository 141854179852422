import * as React from "react"

import { PageNumber } from "./PageNumber"

const MIN_ITEM_COUNT = 5

/**
 * @type {React.FC<import('./type').ReditorUIKitPageNumbersProps>}
 */
const PageNumbers = ({
  page = 1,
  pageCount = 0,
  PageNumberComponent = PageNumber,
  onPageChange,
}) => {
  /** @type {{value: number|string, label: string}[]} */
  let pageItems = []
  let start = 1
  let end = pageCount

  if (pageCount <= MIN_ITEM_COUNT + 2) {
    // then just display all the page numbers from 1 to pageCount
    pageItems = Array.from({ length: pageCount }).map((_, i) => ({
      value: i + 1,
      label: (i + 1).toString(),
    }))
  } else {
    if (page < MIN_ITEM_COUNT) {
      // for page (1~4), all start with 1 and end with either 5 or pageCount
      start = 1
      end = Math.min(pageCount, MIN_ITEM_COUNT)
    } else if (page > pageCount - MIN_ITEM_COUNT + 1) {
      // for example, if pageCount is 11, then page (8~11) all start with 7 and end with 11
      start = Math.max(1, pageCount - MIN_ITEM_COUNT + 1)
      end = pageCount
    } else {
      // for example, if pageCount is 11, then page 5, start with 4 and end with 6
      start = Math.max(1, page - 1)
      end = Math.min(pageCount, page + 1)
    }

    pageItems = Array.from({ length: end - start + 1 }).map((_, i) => ({
      value: i + start,
      label: (i + start).toString(),
    }))

    // if the first page is not 1, then add 1 and '...' to the beginning
    if (pageItems[0].value !== 1) {
      pageItems.unshift({
        value: "...1",
        label: "...",
      })
      pageItems.unshift({
        value: 1,
        label: "1",
      })
    }

    // if the last page is not the last page count, then add '...' and the last page count to the end
    if (pageItems[pageItems.length - 1].value !== pageCount) {
      pageItems.push({
        value: "...2",
        label: "...",
      })
      pageItems.push({
        value: pageCount,
        label: pageCount.toString(),
      })
    }
  }

  if (!pageItems.length) return null

  return (
    <>
      {pageItems.map((pageItem) => {
        return (
          <PageNumberComponent
            disabled={typeof pageItem.value !== "number"}
            active={pageItem.value === page}
            key={pageItem.value.toString()}
            onClick={() => {
              typeof pageItem.value === "number" &&
                onPageChange?.(pageItem.value)
            }}>
            {pageItem.label}
          </PageNumberComponent>
        )
      })}
    </>
  )
}

export { PageNumbers }
