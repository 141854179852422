/**
 * @description this file defines the common types used in the data request,
 */

import {z} from 'zod'

/** @typedef {z.infer<typeof PaginationQuery>} PaginationQuery */
export const PaginationQuery = z.object({
  page: z.number().optional(),
  pageSize: z.number().optional(),
})

/**
 * @param {any} paginationQuery
 * @returns {paginationQuery is PaginationQuery}
 */
export function isPaginationByPage(paginationQuery) {
  // @ts-ignore
  return typeof paginationQuery.page === 'number'
}

/**
 * @description
 * something like this:  ['id1', 'id2'],
 */
const StringValueFilter = z.array(z.string())

/**
 * @description
 * something like this:
 *  {
 *     name: ['name1', 'name2'],
 *  }
 */
const RelationAttributeFilter = z.record(z.array(z.any()).optional())
/** @typedef {z.infer<typeof RelationAttributeFilter>} RelationAttributeFilter */

const DateRangeFilter = z.union([
  z.object({
    from: z.string(),
    to: z.string().optional(),
  }),
  z.object({
    from: z.string().optional(),
    to: z.string(),
  }),
])
/** @typedef {z.infer<typeof DateRangeFilter>} DateRangeFilter */

/**
 * @param {any|undefined} dateRange
 * @returns {dateRange is DateRangeFilter}
 */
export function isDateRangeFilter(dateRange) {
  return (
    typeof dateRange?.from === 'string' || typeof dateRange?.to === 'string'
  )
}

/**
 * @param {any|undefined} filter
 * @returns {filter is RelationAttributeFilter}
 */
export function isRelationAttributeFilter(filter) {
  return (
    typeof filter === 'object' &&
    Object.values(filter).every((value) => Array.isArray(value))
  )
}

/**
 * @description
 * a filter is a record like this:
 * {
 *   attr1: ['value1', 'value2'],  // <- string value filter
 *   dateFilter: {               // <- date range filter
 *     from: "2022-11-01",
 *     to: "2023-01-01",
 *   },
 *   relation: {                 // <- relation attribute filter
 *      "name": ["name1", "name2"]
 *   },
 *   attr2: undefined,           // <- ignore this filter
 * }
 * @typedef {z.infer<typeof GeneralFilters>} GeneralFilters
 **/
export const GeneralFilters = z
  .record(
    z
      .union([StringValueFilter, DateRangeFilter, RelationAttributeFilter])
      .optional(),
  )
  .default({})

/**
 * @description
 * single data response
 */
/** @typedef {z.infer<typeof SingleDataResponse>} SingleDataResponse */
export const SingleDataResponse = z.any()

/**
 * @description
 * response for list of entries
 */
/** @typedef {z.infer<typeof MultiDataResponse>} MultiDataResponse */
export const MultiDataResponse = z.object({
  list: z.array(z.any()).default([]),
  page: z.number().default(1),
  pageSize: z.number().default(25),
  pageCount: z.number().default(0),
  total: z.number().default(25),
  totalItems: z.number().default(0),
  totalPages: z.number().default(0),
})

/**
 * @param {MultiDataResponse} lastPageResult
 * @param {MultiDataResponse[]} allPagesResult
 * @returns {PaginationQuery|undefined}
 */
export function getNextPageParam(lastPageResult, allPagesResult) {
  const {list, ...pagination} = lastPageResult
  if (!lastPageResult.page) return undefined
  if (isPaginationByPage(pagination)) {
    const currentPage = pagination.page
    const totalPages = pagination.pageCount
    if (currentPage >= totalPages) return undefined
    return {
      page: currentPage + 1,
      pageSize: pagination.pageSize,
    }
  }
  return undefined
}

/** @typedef {z.infer<typeof UploadFileResult>} UploadFileResult */
export const UploadFileResult = z.array(
  z.object({
    id: z.coerce.string(),
    url: z.coerce.string(),
  }),
)

/**
 * @description
 *   a general read many parameter,
 * for example:
 *  {
 *    pagination: {
 *      page: 1,
 *      pageSize: 25,
 *    },
 *    filters:  {
 *      attr1: ['value1', 'value2'],  // <- string value filter
 *      dateFilter: {               // <- date range filter
 *        from: "2022-11-01",
 *        to: "2023-01-01",
 *      },
 *      relation: {                 // <- relation attribute filter
 *         "name": ["name1", "name2"]
 *      },
 *      attr2: undefined,           // <- ignore this filter
 *    }
 * }
 */
/** @typedef {z.infer<typeof ReadManyParams>} ReadManyParams */
export const ReadManyParams = z.object({
  pagination: PaginationQuery.optional(),

  filters: GeneralFilters.optional(),
  search: z.record(z.string()).optional(),
})

/** @typedef {z.infer<typeof CountParams>} CountParams */
export const CountParams = z.object({
  filters: GeneralFilters.optional(),
})

/** @typedef {z.infer<typeof DistinctParams>} DistinctParams */
export const DistinctParams = z.object({
  columns: GeneralFilters.optional(),
})
