import React from "react"
import { useAlertDialogStore } from "./store"

/**
 * @type {React.FC<{
 *  component: React.FC<import('./type').TAlertDialogInstanceState>
 * }>}
 */
export const AlertDialogContainer = ({ component: DialogComponent }) => {
  const store = useAlertDialogStore()
  return (
    <>
      {store.dialogIds.map((id) => {
        const dialogParams = store.dialogMap[id]
        return (
          <DialogComponent
            key={id}
            closeAlert={() => store.remove(id)}
            {...dialogParams}
          />
        )
      })}
    </>
  )
}
