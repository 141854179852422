import React from "react"
import { twMerge } from "../twmerge"
import { buttonVariants } from "./variants"

/**
 * @type {React.FC<import('./type').ReditorUIKitButtonProps>}
 */
export const Button = ({ variant, className, ...props }) => (
  <button
    {...props}
    className={twMerge(buttonVariants({ variant }), className)}
  />
)
