import { z } from 'zod'

import { Image, Images } from './image.js'
export const BP_TYPE_STORE = 'store'
export const BP_TYPE_DEALER = 'dealer'
export const BP_TYPE_DISTRIBUTOR = 'distributor'

export const BpTypeEnum = z.enum([
    BP_TYPE_DISTRIBUTOR,
    BP_TYPE_DEALER,
    BP_TYPE_STORE,
])
export const StatusEnum = z.enum(['no', 'invoice', 'all'])

/** @typedef {z.infer<typeof BusinessPartner>} BusinessPartner */
export const BusinessPartner = z.object({
    id: z.coerce.number(),
    accountNum: z.string(),
    parentId: z.number().nullish(),
    bpType: BpTypeEnum,
    status: StatusEnum.nullish(),
    sourceType: z.string().nullish(),
    sourceRecId: z.number().nullish(),
    gsc: z.string(),
    name: z.string(),
    phone: z.string(),
    email: z.string(),
    fax: z.string().nullish(),
    city: z.string(),
    street: z.string(),
    zipcode: z.string(),
    stateProvince: z.string().nullish(),
    country: z.string(),
    district: z.string().nullish(),
    latitude: z.number().nullish(),
    longitude: z.number().nullish(),
    createdAt: z.coerce.date().nullish(),
    modifiedAt: z.coerce.date().nullish(),
    openingHourTemplateId: z.number().nullish(),
})
