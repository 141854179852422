import { cva } from "class-variance-authority"

export const dialogContainerVariants = cva(
  ["relative", "z-dialog", "text-grey"],
  {},
)

export const dialogPanelVariants = cva([
  "px-8",
  "py-6",
  "lg:px-32",
  "lg:py-10",
  "rounded",
  "bg-white",
  "mx-auto",
  "max-w-full",
  "lg:max-w-screen-lg",
])

export const dialogPanelContainerVariants = cva([
  "fixed",
  "inset-0",
  "flex",
  "w-screen",
  "items-center",
  "justify-center",
  "p-4",
])

export const dialogActionsVariants = cva([
  "flex",
  "items-center",
  "mt-[2.625rem]",
  "justify-end",
])
