import {z} from 'zod'
import format from 'date-fns/format'

import {
  GeneralFilters,
  isDateRangeFilter,
  isRelationAttributeFilter,
} from '@reditor/core/domains/data-request'

const defaultPageSize = 25

export const RequestParams = z.object({
  pagination: z
    .object({
      page: z.coerce.number().default(1),
      pageSize: z.coerce.number().default(defaultPageSize),
    })
    .default({
      page: 1,
      pageSize: defaultPageSize,
    }),
  filters: GeneralFilters.optional().default({}).catch({}),
})

/**
 * @description randomly returns an item from the array
 * @param {Array<any>} items
 */
export const getRandomItem = (items) => {
  return items[Math.floor(Math.random() * items.length)]
}

/**
 * @description randomly returns an item from the array or return nothing
 * @param {Array<any>} items
 */
export const getRandomItemOrNull = (items) => {
  return Math.random() > 0.3 ? getRandomItem(items) : null
}

/**
 * @description randomly returns count of items from the array
 * @param {Array<any>} items
 * @param {number} [count]
 */
export const getRandomItems = (items, count) => {
  // if count is undefined, return random number of items
  if (count === undefined) {
    count =
      Math.random() > 0.3 ? Math.floor(Math.random() * items.length) : undefined
  }

  if (!count) return []
  return items.sort(() => 0.5 - Math.random()).slice(0, count)
}

/**
 * @description randomly returns any date from now to 1 year ago
 * @param {string} [dateFormat]
 */
export const randomDate = (dateFormat) => {
  const now = new Date()
  const past = new Date(now.getTime() - 365 * 24 * 60 * 60 * 1000)
  const randomDate = new Date(
    past.getTime() + Math.random() * (now.getTime() - past.getTime()),
  )
  return dateFormat ? format(randomDate, dateFormat) : randomDate
}

/**
 * @description randomly returns any time in 24 hours format
 * @param {string} [timeFormat]
 */
export const randomTime = (timeFormat) => {
  const hours = Math.floor(Math.random() * 24)
  // only generate minutes in 15 minutes interval, ex. 00, 15, 30, 45
  const minutes = Math.floor(Math.random() * 4) * 15
  const seconds = 0
  const milliseconds = 0
  const randomTime = new Date(0, 0, 0, hours, minutes, seconds, milliseconds)
  return timeFormat ? format(randomTime, timeFormat) : randomTime
}

/**
 * @description filter items with general filters
 * @param {Array<any>} items
 * @param {GeneralFilters} filters
 */
export const filterItems = (items = [], filters) => {
  return items.filter((item) => {
    return Object.entries(filters).every(([key, values]) => {
      // @ts-ignore
      const itemValue = item[key]
      if (Array.isArray(values)) {
        if (!values || values.length === 0) return true
        return values.some((value) => {
          // compare to id or the value it self
          if (Array.isArray(itemValue)) {
            return itemValue.some(
              (v) => v?.id?.toString() == String(value) || v == value,
            )
          }
          return (
            // @ts-ignore
            item[key]?.id?.toString() == String(value) || item[key] == value
          )
        })
      } else if (isDateRangeFilter(values)) {
        // compare if the itemValue is within from and to
        return (
          (!values.from || itemValue >= values.from) &&
          (!values.to || itemValue <= values.to)
        )
      } else if (isRelationAttributeFilter(values)) {
        return Object.entries(values).every(([relationKey, keyValues]) => {
          if (!keyValues || keyValues.length === 0) return true
          return keyValues.some((value) => {
            // compare to the itemValue
            // @ts-ignore
            if (Array.isArray(itemValue)) {
              return itemValue.some(
                (v) => v?.[relationKey]?.toString() == String(value),
              )
            }
            return (
              // @ts-ignore
              itemValue?.[relationKey]?.toString() == String(value)
            )
          })
        })
      } else {
        return true
      }
    })
  })
}
