import {handlers as dummyHandlers} from './dummy'
import {handlers as addressesHandlers} from './addresses'
import {handlers as closingDatesHandlers} from './closing-dates'
import {handlers as pricingsHandlers} from './pricings'
import {handlers as discountsHandlers} from './discounts'
import {handlers as openingHoursHandlers} from './opening-hours'
import {handlers as openingHourTemplatesHandlers} from './opening-hour-templates'
import {handlers as servicesHandlers} from './services'
import {handlers as serviceMapsHandlers} from './service-maps'
import {handlers as dealerConfigsHandlers} from './dealer-configs'
import {handlers as storeConfigsHandlers} from './store-configs'
import {handlers as sapAxMappingsHandlers} from './sap-ax-mappings'
import {handlers as businessPartnerWritablesHandlers} from './business-partner-writables'
import {handlers as salesCompaniesHandlers} from './sales-companies'
import {handlers as businessPartnersHandlers} from './business-partners'

export const handlers = [
  ...dummyHandlers,
  ...businessPartnersHandlers,
  ...salesCompaniesHandlers,
  ...businessPartnerWritablesHandlers,
  ...sapAxMappingsHandlers,
  ...storeConfigsHandlers,
  ...dealerConfigsHandlers,
  ...serviceMapsHandlers,
  ...servicesHandlers,
  ...openingHourTemplatesHandlers,
  ...openingHoursHandlers,
  ...discountsHandlers,
  ...pricingsHandlers,
  ...closingDatesHandlers,
  ...addressesHandlers,
]
