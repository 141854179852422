import {z} from 'zod'

/** @typedef {z.infer<typeof Image>} Image */
export const Image = z.object({
  id: z.coerce.string(),
  url: z.string(),
})

/** @typedef {z.infer<typeof Images>} Images */
export const Images = z.array(Image)

/**
 * @param {any} media
 * @returns {media is Image}
 */
export function isImage(media) {
  return media?.url && media?.id
}
