import * as React from "react"
import { twMerge } from "../twmerge"

/** @type {ForwardRefRenderHeading} */
const h1ForwardRefRender = ({ className, children, ...props }, ref) => (
  <h1 ref={ref} className={twMerge("text-h1", className)} {...props}>
    {children}
  </h1>
)
const H1 = React.forwardRef(h1ForwardRefRender)
H1.displayName = "H1"

/** @type {ForwardRefRenderHeading} */
const h2ForwardRefRender = ({ className, children, ...props }, ref) => (
  <h2 ref={ref} className={twMerge("text-h2", className)} {...props}>
    {children}
  </h2>
)
const H2 = React.forwardRef(h2ForwardRefRender)
H2.displayName = "H2"

/** @type {ForwardRefRenderHeading} */
const h3ForwardRefRender = ({ className, children, ...props }, ref) => (
  <h3 ref={ref} className={twMerge("text-h3", className)} {...props}>
    {children}
  </h3>
)
const H3 = React.forwardRef(h3ForwardRefRender)
H3.displayName = "H3"

/** @type {ForwardRefRenderHeading} */
const h4ForwardRefRender = ({ className, children, ...props }, ref) => (
  <h4 ref={ref} className={twMerge("text-h4", className)} {...props}>
    {children}
  </h4>
)
const H4 = React.forwardRef(h4ForwardRefRender)
H4.displayName = "H4"

export { H1, H2, H3, H4 }

/**
 * @typedef {React.ForwardRefRenderFunction<
 *   HTMLHeadingElement,
 *   React.HTMLAttributes<HTMLHeadingElement>
 * >} ForwardRefRenderHeading
 */
