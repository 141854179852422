import React from "react"

import { BaseDialog } from "./BaseDialog"

/**
 * @type {React.FC<import('lib/alert-dialog/type').TAlertDialogInstanceState>}
 */
export const AlertDialog = (props) => {
  /**
   * @type {import('./type').ReditorUIKitBaseDialogAction[]}
   */
  const defaultActions = [
    {
      text: "OK",
      shouldCloseDialog: true,
    },
  ]
  return (
    <BaseDialog
      open={true}
      title={props.title}
      actions={props.actions ?? defaultActions}
      onClose={props.closeAlert}>
      {props.message}
    </BaseDialog>
  )
}
