import { flip, offset, shift, useFloating } from "@floating-ui/react-dom"
import { Listbox } from "@headlessui/react"
import React from "react"

import { Icon } from "../icons"
import { twMerge } from "../twmerge"
import { DropdownSearchbox, NoMatchesText, useSearchable } from "./search"
import {
  dropdownContentVariants,
  dropdownIconVariants,
  dropdownItemVariants,
  dropdownTriggerArrowVariants,
  dropdownTriggerVariants,
} from "./variants"

/**
 * @template T
 * @param {import('./type').ReditorUIKitSingleDropdownProps<T>} props
 */
export const Dropdown = (props) => {
  const { refs, floatingStyles } = useFloating({
    placement: "bottom-start",
    strategy: "absolute",
    middleware: [offset(4), flip(), shift()],
  })

  const triggerRef = React.useRef(
    /** @type {HTMLButtonElement | null} */ (null),
  )
  const [menuWidth, setMenuWidth] = React.useState(0)
  const [value, setValue] = React.useState(
    () => props.value ?? props.defaultValue,
  )

  const { defaultFilter, search, setSearch } = useSearchable()

  React.useLayoutEffect(() => {
    if (triggerRef.current) {
      setMenuWidth(triggerRef.current.getBoundingClientRect().width)
    }
  }, [])

  const filteredOptions = props.options.filter(defaultFilter).map((o) => (
    <Listbox.Option
      disabled={o.disabled}
      key={o.id}
      value={o}
      className={twMerge(
        dropdownItemVariants({
          intent: o.disabled
            ? "disabled"
            : o === value
              ? "selected"
              : "default",
        }),
        props.menuItemClassName,
      )}>
      {o.text}
    </Listbox.Option>
  ))

  return (
    <Listbox
      as={"div"}
      ref={refs.setReference}
      value={props.value ?? value}
      className={props.containerClassName}
      onChange={(v) => {
        setValue(v)
        props.onChange?.(v)
      }}>
      {({ open }) => {
        const intent = open ? "opened" : "closed"
        return (
          <div className="flex flex-col">
            {props.label && (
              <Listbox.Label
                className={twMerge(
                  "text-grey text-c4 mb-1",
                  props.labelClassName,
                )}>
                {props.label}
              </Listbox.Label>
            )}
            <Listbox.Button
              ref={triggerRef}
              className={twMerge(
                dropdownTriggerVariants({
                  intent,
                  selected: !!value,
                }),
                props.className,
              )}>
              <span className="flex items-center">
                {props.icon ? (
                  <Icon
                    icon={props.icon}
                    size={20}
                    className={dropdownIconVariants({
                      selected: !!value,
                    })}
                  />
                ) : null}
                {value?.text ?? props.placeholder ?? ""}
              </span>
              <Icon
                icon="btn_dropdown"
                className={dropdownTriggerArrowVariants({ intent })}
                size={24}
              />
            </Listbox.Button>
            <Listbox.Options
              ref={refs.setFloating}
              className={dropdownContentVariants()}
              style={{
                ...floatingStyles,
                minWidth: menuWidth,
              }}>
              {props.searchable ? (
                <DropdownSearchbox
                  value={search}
                  placeholder={props.searchBoxPlaceholder}
                  onChange={({ target: { value } }) => setSearch(value)}
                />
              ) : null}
              {filteredOptions.length > 0 ? (
                <div className="overflow-auto">{filteredOptions}</div>
              ) : props.searchable ? (
                <NoMatchesText>{props.searchBoxNoResultsText}</NoMatchesText>
              ) : null}
            </Listbox.Options>
          </div>
        )
      }}
    </Listbox>
  )
}
