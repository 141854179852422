import * as React from "react"

import { QueryProvider } from "../lib/react-query"

/**
 * @param {{children: React.ReactElement}} param0
 */
export default function RootProvider({ children }) {
  return <QueryProvider>{children}</QueryProvider>
}
