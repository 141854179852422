import { flip, offset, shift, useFloating } from "@floating-ui/react-dom"
import { Listbox } from "@headlessui/react"
import { useVirtualizer } from "@tanstack/react-virtual"
import * as React from "react"

import { Icon } from "../icons"
import { twMerge } from "../twmerge"
import { DropdownSearchbox, NoMatchesText, useSearchable } from "./search"
import {
  dropdownContentVariants,
  dropdownItemVariants,
  dropdownTriggerArrowVariants,
  dropdownTriggerVariants,
} from "./variants"

const OPTION_ALL_VALUE = "__@@dropdown-all"

const OPTION_ALL = Object.freeze({
  text: "All",
  value: OPTION_ALL_VALUE,
  id: OPTION_ALL_VALUE,
  disabled: false,
})

/**
 * @template T
 * @param {import('./type').ReditorUIKitDropdownOption<T>[]} selectedOption
 * @param {{
 *  placeholder?: string,
 *  options?: import('./type').ReditorUIKitDropdownOption<T>[],
 * }} [props]
 * @returns
 */
const renderDropdownTitleDefault = (selectedOption, props = {}) => {
  const { placeholder, options = [] } = props
  // if all the options are selected, display the placeholder
  if (
    placeholder &&
    (selectedOption.length === options.length || selectedOption.length === 0)
  ) {
    return placeholder
  }
  return selectedOption.map((option) => option.text).join(", ")
}

/**
 * @template T
 * @param {import('./type').ReditorUIKitMultiDropdownProps<T>} props
 */
const MultiDropdown = ({ titleForAll = "All", ...props }) => {
  const { refs, floatingStyles } = useFloating({
    placement: "bottom-start",
    strategy: "absolute",
    middleware: [offset(4), flip(), shift()],
  })

  const [internalSelectedOption, setSelectedOption] = React.useState(
    () => props.value ?? props.defaultValue ?? [],
  )
  const selectedOption = props.value ?? internalSelectedOption

  const { defaultFilter, search, setSearch } = useSearchable()
  const shouldShowAllOption = !search

  /**
   * @type {React.MutableRefObject<HTMLDivElement | null>}
   */
  const scrollableRef = React.useRef(null)

  /**
   * @param {import('./type').ReditorUIKitDropdownOption<T>[]} newSelectedOption
   */
  const handleOnChange = (newSelectedOption) => {
    let nextSelectedOptions = newSelectedOption.filter(
      (o) => o.value !== OPTION_ALL.value,
    )

    // this change is caused by the press of "All option"
    if (newSelectedOption.length !== nextSelectedOptions.length) {
      if (nextSelectedOptions.length === 0) {
        // select all
        nextSelectedOptions = props.options
      } else {
        // select none
        nextSelectedOptions = []
      }
    }

    props.onChange?.(nextSelectedOptions)
    setSelectedOption(nextSelectedOptions)
  }

  const triggerRef = React.useRef(
    /** @type {HTMLButtonElement | null} */ (null),
  )
  const [menuWidth, setMenuWidth] = React.useState(0)
  React.useLayoutEffect(() => {
    if (triggerRef.current) {
      setMenuWidth(triggerRef.current.getBoundingClientRect().width)
    }
  }, [])

  const filteredOptions = props.options.filter(defaultFilter)

  const virtualizer = useVirtualizer({
    count: filteredOptions.length + (shouldShowAllOption ? 1 : 0),
    getScrollElement: () => scrollableRef.current,
    estimateSize: () => 35,
    overscan: 10,
  })
  const virtualItems = virtualizer.getVirtualItems()

  // A workaround to calculate estimated width of the options
  // since the options are positioned absolutely by virtualizer
  const widestOptionWidth = React.useMemo(() => {
    const padding = 16 * 2
    const iconWidth = 16
    const gap = 12
    const preservedWidth = padding + iconWidth + gap

    return filteredOptions.reduce(
      (maxWidth, o) =>
        Math.max(
          maxWidth,
          o.text.length * 8 + preservedWidth,
          refs.floating.current?.clientWidth ?? 0, // if the options are too short, use menu width instead
        ),
      // use the "All" option as the initial width
      OPTION_ALL.text.length * 8 + preservedWidth,
    )
  }, [filteredOptions, refs.floating])

  return (
    <Listbox
      multiple
      as={"div"}
      ref={refs.setReference}
      value={selectedOption}
      className={props.containerClassName}
      onChange={handleOnChange}
      disabled={props.disabled}>
      {({ open }) => {
        const intent = open ? "opened" : "closed"
        const selected = selectedOption.length > 0
        return (
          <div className="flex flex-col">
            {props.label && (
              <Listbox.Label
                className={twMerge(
                  "text-grey text-c4 mb-1",
                  props.labelClassName,
                )}>
                {props.label}
              </Listbox.Label>
            )}
            <Listbox.Button
              ref={triggerRef}
              className={twMerge(
                dropdownTriggerVariants({
                  intent,
                  selected,
                }),
                props.className,
              )}>
              <span className="truncate">
                {renderDropdownTitleDefault(selectedOption, props)}
              </span>
              <Icon
                icon="btn_dropdown"
                className={dropdownTriggerArrowVariants({ intent })}
                size={24}
              />
            </Listbox.Button>
            <Listbox.Options
              ref={refs.setFloating}
              className={dropdownContentVariants()}
              style={{
                ...floatingStyles,
                minWidth: menuWidth,
              }}>
              {props.searchable ? (
                <DropdownSearchbox
                  value={search}
                  placeholder={props.searchBoxPlaceholder}
                  onChange={({ target: { value } }) => setSearch(value)}
                />
              ) : null}
              <div ref={scrollableRef} className="relative overflow-y-auto">
                <div
                  style={{
                    height: virtualizer.getTotalSize() || "max-content",
                    width: "100%",
                    position: "relative",
                  }}>
                  {filteredOptions.length > 0 ? (
                    virtualItems.map((virtualItem) => {
                      const virtualItemKey = virtualItem.key

                      /**
                       * @type {React.CSSProperties}
                       */
                      const virtualItemStyle = {
                        position: "absolute",
                        top: 0,
                        left: 0,
                        height: virtualItem.size,
                        width: widestOptionWidth,
                        transform: `translateY(${virtualItem.start}px)`,
                      }

                      if (shouldShowAllOption && virtualItem.index === 0)
                        return (
                          // a special option for "All value" option, please refer to the UX
                          <Listbox.Option
                            key={virtualItemKey}
                            value={OPTION_ALL}
                            data-index={virtualItem.index}
                            ref={virtualizer.measureElement}
                            className={twMerge(
                              dropdownItemVariants({
                                intent: "multi",
                              }),
                              props.menuItemClassName,
                            )}
                            style={virtualItemStyle}>
                            <>
                              <Icon
                                size={16}
                                icon={
                                  selectedOption.length === 0
                                    ? "btn_checkbox"
                                    : selectedOption.length >=
                                        props.options.length
                                      ? "btn_checkbox_selected"
                                      : "btn_checkbox_clear"
                                }
                                className={
                                  selectedOption.length === 0
                                    ? "text-deepBlue-40"
                                    : "text-primary"
                                }
                              />
                              <span className="text-c1 text-black">
                                {titleForAll}
                              </span>
                            </>
                          </Listbox.Option>
                        )
                      const optionIndex = shouldShowAllOption
                        ? virtualItem.index - 1
                        : virtualItem.index
                      const option = filteredOptions[optionIndex]

                      return (
                        <Listbox.Option
                          key={virtualItemKey}
                          data-index={virtualItem.index}
                          ref={virtualizer.measureElement}
                          value={option}
                          disabled={option.disabled}
                          className={twMerge(
                            dropdownItemVariants({
                              intent: "multi",
                            }),
                            props.menuItemClassName,
                          )}
                          style={virtualItemStyle}>
                          {({ selected }) => (
                            <>
                              <Icon
                                size={16}
                                icon={
                                  selected
                                    ? "btn_checkbox_selected"
                                    : "btn_checkbox"
                                }
                                className="text-deepBlue-40 ui-selected:text-primary ui-disabled:text-grey-light min-w-4"
                              />
                              <span>{option.text}</span>
                            </>
                          )}
                        </Listbox.Option>
                      )
                    })
                  ) : (
                    <NoMatchesText>
                      {props.searchBoxNoResultsText}
                    </NoMatchesText>
                  )}
                </div>
              </div>
            </Listbox.Options>
          </div>
        )
      }}
    </Listbox>
  )
}

export { MultiDropdown }
