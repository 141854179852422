/**
 * @description
 * FlexTable is a table component that uses flex div to layout the table.
 * therefore, you have to **specify the width of each column**
 * @example,
 *   <FlexTable.TableHead style={{width: 200}}>
 *      <H4>{item.name}</H4>
 *   </FlexTable.TableHead>
 *   <FlexTable.TableCell style={{width: 200}}>
 *      name
 *   </FlexTable.TableCell>
 *
 * default style is `fit` which means the width of the column is determined by the content
 * if you want to span the table to the full width, you can use `width="full"` to the table
 *
 * @example
 *   <FlexTable.Table width='full'>
 *      <FlexTable.TableBody>
 *      </FlexTable.TableBody>
 *   </FlexTable.Table>
 *
 * if you need to cut the text, you can use `line-clamp-1` to the text
 * @example
 * <FlexTable.TableCell style={{width: 250}}>
 *   <span className='text-ellipsis line-clamp-1'>
 *     {someLongText}
 *   </span>
 * </FlexTable.TableCell>
 *
 */
import * as React from "react"
import { twMerge } from "../twmerge"
import { flexTableWrapperVariant } from "./variants"

/**
 * This just works as a "Slot" for the table,
 * so we could put something inside the table container, but before the table,
 * for example, the filter bar
 * @type {React.FC<React.PropsWithChildren>}
 **/
const TableFront = ({ children }) => <>{children}</>
TableFront.displayName = "TableFront"

/** @type {ForwardRefRenderTable} */
const tableForwardRefRender = (
  { className, children, width, containerClassName, ...props },
  ref,
) => {
  /** @type {React.ReactElement[]} */
  let front = []
  /** @type {React.ReactElement[]} */
  let tableContent = []

  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) return
    if (child.type === TableFront) {
      front.push(child)
    } else {
      tableContent.push(child)
    }
  })

  return (
    <div
      className={twMerge(
        "w-full overflow-x-auto overflow-y-hidden flex flex-col",
        containerClassName,
      )}>
      <div className={flexTableWrapperVariant({ width })}>
        {front}
        <div
          className={twMerge("flex flex-col overflow-y-auto", className)}
          role="table"
          ref={ref}
          {...props}>
          <div className="w-full">{tableContent}</div>
        </div>
      </div>
    </div>
  )
}
const Table = React.forwardRef(tableForwardRefRender)
Table.displayName = "Table"

/** @type {ForwardRefRenderHeadElement} */
const tableHeaderForwardRefRender = (
  { className, children, ...props },
  ref,
) => (
  <header
    className={twMerge("sticky top-0 z-10 text-grey-70 ", className)}
    role="rowgroup"
    ref={ref}
    {...props}>
    {children}
  </header>
)
const TableHeader = React.forwardRef(tableHeaderForwardRefRender)
TableHeader.displayName = "TableHeader"

/** @type {ForwardRefRenderDivElement} */
const tableRowForwardRefRender = ({ className, children, ...props }, ref) => (
  <div
    className={twMerge(
      "w-full flex flex-row px-4 border-b border-grey-divider-2 bg-white",
      className,
    )}
    role="row"
    ref={ref}
    {...props}>
    {children}
  </div>
)
const TableRow = React.forwardRef(tableRowForwardRefRender)
TableRow.displayName = "TableRow"

/** @type {ForwardRefRenderDivElement} */
const tableHeadForwardRefRender = ({ className, children, ...props }, ref) => (
  <div
    className={twMerge(
      "grow mr-7 last:mr-0 select-none inline-flex flex-row items-center text-c3 h-10",
      className,
    )}
    role="columnheader"
    ref={ref}
    {...props}>
    {children}
  </div>
)
const TableHead = React.forwardRef(tableHeadForwardRefRender)
TableHead.displayName = "TableHead"

/** @type {ForwardRefRenderDivElement} */
const tableCellForwardRefRender = ({ className, children, ...props }, ref) => (
  <div
    className={twMerge(
      "grow mr-7 last:mr-0 py-3 inline-flex flex-row items-center text-c2 leading-5",
      className,
    )}
    role="cell"
    ref={ref}
    {...props}>
    {children}
  </div>
)
const TableCell = React.forwardRef(tableCellForwardRefRender)
TableCell.displayName = "TableCell"

/** @type {ForwardRefRenderDivElement} */
const tableBodyForwardRefRender = ({ className, children, ...props }, ref) => (
  <div className="relative">
    <div
      className={twMerge("", className)}
      role="rowgroup"
      ref={ref}
      {...props}>
      {children}
    </div>
  </div>
)
const TableBody = React.forwardRef(tableBodyForwardRefRender)
TableBody.displayName = "TableBody"

export {
  Table,
  TableBody,
  TableCell,
  TableFront,
  TableHead,
  TableHeader,
  TableRow,
}

/**
 * @typedef {React.ForwardRefRenderFunction<
 *   HTMLDivElement,
 *   React.HTMLAttributes<HTMLDivElement>
 * >} ForwardRefRenderDivElement
 *
 * @typedef {React.ForwardRefRenderFunction<
 *   HTMLDivElement,
 *   import('./type').ReditorUIKitFlexTableTableProps
 * >} ForwardRefRenderTable
 *
 * @typedef {React.ForwardRefRenderFunction<
 *   HTMLHeadElement,
 *   React.HTMLAttributes<HTMLHeadElement>
 * >} ForwardRefRenderHeadElement
 */
