import { z } from 'zod'

export const WeekdayEnum = z.enum([
    'mon',
    'tue',
    'wed',
    'thr',
    'fri',
    'sat',
    'sun',
])

const timeLikeRegexp = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/
let timeLikeSchema = z.union([
    z.optional(z.literal("")),
    z.string().regex(timeLikeRegexp).nullish()
])


/** @typedef {z.infer<typeof OpeningHour>} OpeningHour */
export const OpeningHour = z.object({
    id: z.coerce.number(),
    axOpeningHourRecId: z.number().nullish(),
    axOpeningHourTemplateRecId: z.number().nullish(),
    gsc: z.string().nullish(),
    weekday: WeekdayEnum.nullish(),
    morningFrom: timeLikeSchema,
    morningTo: timeLikeSchema,
    afternoonFrom: timeLikeSchema,
    afternoonTo: timeLikeSchema,
    eveningFrom: timeLikeSchema,
    eveningTo: timeLikeSchema,
    description: z.string().nullish(),
    modifiedAt: z.date().nullish(),
})
